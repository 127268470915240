(function($){


  var tap = ("ontouchstart" in document.documentElement);

  function show_catalog() {
    console.log('Showed');
    $('#overlay').fadeIn(100);
    $("#mobile-catalog" ).animate({
      'opacity': "1",
    }, 150, function() {
    });

  }

  function hide_catalog(e) {
    e.preventDefault();
    $("#mobile-catalog" ).animate({
      'opacity': "0",
    }, 150, function() {
      $('#overlay').fadeOut(100);
    });
  } /**/


  function hide_filter(e) {
    e.preventDefault();
    $("#side-menu" ).fadeOut(100);
    $('#overlay').fadeOut(100);
  } /**/

  /**/
  /*
  $('.mobile-menu-btn').on('click', function(e) {
    show_menu(e);
  });
  /**/

  $('.panel-bar li a').on('touchstart', function(e) {
    if($(this).parent().hasClass('parent')) {
      var li_class = '.' + $(this).parent().attr('class').split(' ').join('.');
      show_catalog(e);
      $('#mobile-catalog li'+li_class).addClass('active');
      $('#mobile-catalog li' + li_class + ' a').click();
      $('#mobile-catalog li' + li_class + ' a').trigger('mouseenter');
    }
  });

  /*if(!tap) {
    $('.cms-home').on('mouseenter', '.panel-bar', function(e){
      show_catalog(e);
    });
    $(document).on('mouseleave', '#mobile-catalog', function(e){
      hide_catalog(e);
    });
  } 
  $('#desktop_cbtn').on('mouseenter', function(e){
    show_catalog(e);    
  });

  /**/

  if(tap) {
    $(document).on('touchend', '#desktop_cbtn', function(e){
      e.preventDefault();
      e.stopPropagation();
      show_catalog();    
    });
  } else {
    $(document).on('click', '#desktop_cbtn', function(e){
      e.preventDefault();
      e.stopPropagation();
      show_catalog();
    });
  }



  /**/

  $(document).on('click', '#filter-btn', function() {
    $('#overlay-empty').fadeIn(200);
    $('#side-menu').fadeIn(200);
    //$('body').addClass('overflow-hidden');
  });

  /* Close by click on overlay */

  $('#overlay').on('click', '#mobile-catalog > ul > li', function(e) {
    if(e.target.nodeName!='SPAN' || e.target.parentNode.nodeName!='A') {
      e.stopPropagation();
      e.preventDefault();
      if(e.target.nodeName='A') {
        $(this).parent().find('ul').hide();
        $(this).find('ul').show();
      }
    }
  });

  $('#overlay').on('click', function(e) {
    if(e.target.nodeName!='A' && e.target.parentNode.nodeName!='A') {
      hide_catalog(e);
    }
  });

  $('#overlay').on('touchstart', function(e) {
    console.log(e.target.nodeName);
    if(e.target.nodeName!='A' && e.target.parentNode.nodeName!='A') {
      hide_catalog(e);
    }
  });

  $(document).on('click', '#filter-window-close', function(e) {
      $('#overlay-empty').fadeOut(200);
      $('#side-menu').fadeOut(200);
  });

  $(document).on('touchstart', '#filter-window-close', function(e) {
      $('#overlay-empty').fadeOut(200);
      $('#side-menu').fadeOut(200);
  });

  $('#mobile-catalog .close').on('click', function(e) {
    hide_catalog(e);
    $('#mobile-catalog').removeClass('opened');
    $('#mobile-catalog li').removeClass('opened');
    $('#mobile-catalog ul li ul').hide();

  });


  $(document).on('change', 'input.filter-action', function() {
    $('#ajaxwrapper').show();
    var url = $(this).data('url');
    $.post(url, function(r) {
      if (window.history.replaceState) {
        window.history.replaceState('none', $('title').text(), url);
      }

      var menu_display = $('#side-menu').attr('display');
      content = $(r).find('#maincontent');
      $('#maincontent').replaceWith(content);

      description = $(r).find('.page-description');
      $('.page-description').replaceWith(description);

      $('#ajaxwrapper').hide();
      $('#side-menu').show();
    });
  });


      $.get('/rtcatalog/menu/', function(r) {

        console.log('Loaded');
        $('#topnav_sidebar').html(r);
      });

})(jQuery);
